import React, { useState, useEffect } from "react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";
import { ShoppingBasket } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { useHistory } from "react-router-dom";
import { signOut } from "./firebase";

function Header() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [activeLink, setActiveLink] = useState(null);
  const history = useHistory();

  // Обработчик выделения активного пункта меню (для десктопа)
  const handleActiveLink = (e) => {
    setActiveLink(e.target.id);
  };

  // Обработчик аутентификации (Sign In/Sign Out)
  const handleAuthentication = () => {
    if (user) {
      signOut()
        .then(() => {
          dispatch({
            type: "SET_USER",
            user: null,
          });
          history.push("/");
        })
        .catch((error) => {
          console.error("Sign Out Error", error);
        });
    }
  };

  // Состояние для показа/скрытия мобильного меню
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  // Состояние для мобильных подменю (какой пункт раскрыт)
  const [openMenu, setOpenMenu] = useState(null);
  const handleMobileMenuToggle = (menuId) => {
    setOpenMenu(openMenu === menuId ? null : menuId);
  };

  // Состояние для показа/скрытия строки поиска
  const [searchActive, setSearchActive] = useState(false);
  const handleSearchClick = () => {
    setSearchActive(!searchActive);
  };

  // Определяем, мобильное ли устройство (ширина окна ≤ 600px)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Закрываем меню и строку поиска при клике вне их области
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (searchActive && !event.target.closest(".header__search")) {
        setSearchActive(false);
      }
      if (
        menuOpen &&
        !event.target.closest(".header__menuIcon") &&
        !event.target.closest(".header__mobileNav")
      ) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [searchActive, menuOpen]);

  // Если активна строка поиска на мобильном – скрываем логотип и блок логина
  const shouldHideLogoAndLogin = searchActive && isMobile;

  // Новая структура меню
  const menuItems = [
    {
      id: "events",
      label: "Events",
      submenu: ["Parties", "Festivals", "Concerts", "Local Gatherings"],
    },
    {
      id: "marketplace",
      label: "Marketplace",
      submenu: [
        {
          group: "Goods for Sale",
          items: [
            "Electronics",
            "Clothing & Accessories",
            "Home & Furniture",
            "Vehicles",
          ],
        },
        {
          group: "Services",
          items: [
            "Rentals (Bikes, Cars)",
            "Health & Wellness",
            "Beauty & Spa",
            "Repairs & Maintenance",
            "Education & Tutoring",
            "Real Estate Services",
          ],
        },
      ],
    },
    {
      id: "bookings",
      label: "Bookings",
      submenu: ["Hotels", "Rooms", "Apartments", "Villas", "Transfers"],
    },
    {
      id: "flights",
      label: "Flights",
      submenu: [],
    },
    {
      id: "attractions",
      label: "Attractions",
      submenu: ["Beaches", "Tours", "Activities", "Food", "Yoga"],
    },
    {
      id: "news",
      label: "News",
      submenu: ["Society", "Real Estate", "Technology", "Automotive"],
    },
    {
      id: "forum",
      label: "Forum",
      submenu: [],
    },
  ];

  return (
    <div className="header">
      {/* Логотип */}
      <Link to="/" className={shouldHideLogoAndLogin ? "mobile-hidden" : ""}>
        <img className="header_logo" src="/images/goalogo2.jpg" alt="Logo" />
      </Link>

      {/* Область поиска */}
      <div className={`header__search ${searchActive ? "active" : ""}`}>
        <input className="header__searchInput" type="search" />
        <SearchIcon
          className="header__searchIcon"
          onClick={handleSearchClick}
        />
      </div>

      {/* Иконка гамбургера */}
      <MenuIcon className="header__menuIcon" onClick={handleMenuToggle} />

      {/* Десктопное меню */}
      {!isMobile && (
        <div className="header__menu">
          {menuItems.map((item) => (
            <div key={item.id} className="header__menuItem">
              <div
                id={item.id}
                className={`header__option ${
                  activeLink === item.id ? "active" : ""
                }`}
                onClick={handleActiveLink}
              >
                {item.label}
              </div>
              {item.submenu && item.submenu.length > 0 && (
                <div className="header__submenu">
                  {typeof item.submenu[0] === "object" && item.submenu[0].group
                    ? item.submenu.map((group, idx) => (
                        <div key={idx} className="header__submenuGroup">
                          <div className="header__submenuGroupHeader">
                            {group.group}
                          </div>
                          {group.items.map((subitem, idx2) => (
                            <div key={idx2} className="header__submenuItem">
                              {subitem}
                            </div>
                          ))}
                        </div>
                      ))
                    : item.submenu.map((subitem, idx) => (
                        <div key={idx} className="header__submenuItem">
                          {subitem}
                        </div>
                      ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Блок регистрации/логина */}
      <Link to={!user && "/login"}>
        <div
          className={`header__login ${
            shouldHideLogoAndLogin ? "mobile-hidden" : ""
          }`}
        >
          <div onClick={handleAuthentication}>
            <span className="header__optionLineOne">
              Hi {user ? user.name : "Guest"}
            </span>
            <span className="header__optionLineTwo">
              {user ? "Sign Out" : "Sign In"}
            </span>
          </div>
        </div>
      </Link>

      {/* Корзина */}
      <Link to="/checkout">
        <div className="header__optionBasket">
          <ShoppingBasket />
          <span className="header__optionLineTwo header__basketCount">
            {basket?.length}
          </span>
        </div>
      </Link>

      {/* Мобильное меню */}
      {isMobile && menuOpen && (
        <div className="header__mobileNav">
          {menuItems.map((item) => (
            <div key={item.id} className="header__mobileMenuItem">
              <div
                className="header__option"
                onClick={() =>
                  item.submenu && item.submenu.length > 0
                    ? handleMobileMenuToggle(item.id)
                    : handleMenuToggle()
                }
              >
                {item.label}
                {item.submenu && item.submenu.length > 0 && (
                  <span className="mobile-menu-icon">
                    {openMenu === item.id ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                  </span>
                )}
              </div>
              {item.submenu &&
                item.submenu.length > 0 &&
                openMenu === item.id && (
                  <div className="header__submenu mobile">
                    {typeof item.submenu[0] === "object" &&
                    item.submenu[0].group
                      ? item.submenu.map((group, idx) => (
                          <div key={idx} className="header__submenuGroup">
                            <div className="header__submenuGroupHeader">
                              {group.group}
                            </div>
                            {group.items.map((subitem, idx2) => (
                              <div key={idx2} className="header__option">
                                {subitem}
                              </div>
                            ))}
                          </div>
                        ))
                      : item.submenu.map((subitem, idx) => (
                          <div key={idx} className="header__option">
                            {subitem}
                          </div>
                        ))}
                  </div>
                )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Header;
